<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" :to="{ name: 'login' }">
        <artos-logo />
        <h2 class="brand-text text-primary ml-1">
          OBIZZ
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center bg-left"
        :style="{
          backgroundImage: require('@/assets/images/pages/login-v2.jpg')
        }"
      >
        <!-- <div class="d-lg-flex align-items-center">
          <b-img
            fluid-grow
            height="100%"
            :src="imgUrl"
            alt="Login V2"
          />
        </div> -->

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <!-- {{ this.$t('pages.login.welcome') }} -->
          </b-card-title>
          <b-card-text>
            {{ this.$t('pages.login.pleaseSignIn') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                :label="this.$t('pages.login.singular.userCode')"
                label-for="userCode"
              >
                <validation-provider
                  #default="{ errors }"
                  mode="passive"
                  name="userCode"
                  rules="required"
                >
                  <b-form-input
                    ref="userCode"
                    id="userCode"
                    v-model="form.userCode"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="User Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group
                :label="this.$t('pages.login.singular.password')"
                label-for="password"
                >
                <validation-provider
                  #default="{ errors }"
                  mode="passive"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-block text-right">
                  <b-link :to="{name:'forgot-password'}">
                    <small>{{ this.$t('pages.login.forgotPassword') }}</small>
                  </b-link>
                </div>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isLoading"
                @click="validationForm"
                class="mb-10"
              >
                <b-spinner
                  small
                  type="grow"
                  v-if="isLoading"
                />
                {{ this.$t('pages.login.actions.signIn') }}
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text v-if=isPlatform class="text-center mt-2">
            <span>{{ this.$t('pages.login.newOnOurPlatform') }} </span>
            <br>
            <b-link :to="{ name:'membership-user-signup' }">
              <span>{{ this.$t('pages.login.actions.signUp') }}</span>
            </b-link>
          </b-card-text> -->
          <b-card-text class="text-center mt-10">
            <span class="d-block d-md-inline-block mt-25">
              Power by <span class="d-none d-sm-inline-block"><a href="https://odisys.id">PT ODISYS INDONESIA</a></span>
            </span>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BSpinner, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, /*BImg,*/ BForm, BButton
} from 'bootstrap-vue'
import ArtosLogo from '@/components/logo/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { AbilityBuilder } from '@casl/ability';
import { subjectAbility } from '@/constant'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    ArtosLogo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BSpinner,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    // BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      isLoading: false,
      form: {
        userCode: '',
        password: ''
      },
      isPlatform: process.env.VUE_APP_IS_PLATFORM,
      sideImg: require('@/assets/images/pages/login-v2.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email
    }
  },
  mounted () {
    this.$refs.userCode.focus()
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2.jpg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm () {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const payload = {
            toasts: {
              $toast: this.$toast,
              component: ToastificationContent
            },
            data: this.form
          }

          this.isLoading = true
          this.$store.dispatch('user/signIn', payload)
            .then(async (data) => {
              // useJwt.setToken(res.token)
              // add default rules
              const tempAbility = [
                ...data.user.ability,
                {
                  action: 'manage',
                  subject: subjectAbility.General
                },
                {
                  action: 'manage',
                  subject: subjectAbility.Dashboard
                }
              ]
              data.user.ability = tempAbility

              await localStorage.setItem('userData', JSON.stringify(data.user))
              let { rules } = new AbilityBuilder()

              await this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${data.user.user_name}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully logged in as User. Now you can start to explore!'
                }
              })

              rules = data.user.ability
              console.log('rules => ', rules)
              await this.$ability.update(rules)

              await this.$router.replace('/')
            })
            .catch(e => {
              console.log('error', e)
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.bg-left {
  background-image: url('~@/assets/images/pages/login-v2.jpg');
  background-position: left;
  background-size: cover;
}
</style>
